import React from "react";


const LoginPage = () => {
  return (
    <p>oh, hi. this is awkward.</p>
  );
};

export default LoginPage;
